<div
  class="grid grid-cols-2 md:grid-cols-4 gap-x-3 gap-y-6 md:gap-y-12 md:gap-6"
>
  @for (plant of store().elements; track plant.id) {
    @defer (on viewport) {
      <app-product-list-item
        (selected)="emitSelected(plant, $index)"
        [product]="plant"
        [backlink]="backlink()"
      ></app-product-list-item>
    } @placeholder {
      <div class="h-80 w-full"></div>
    }
  } @empty {
    @if (store().started && !store().loading && store().elements.length === 0) {
      <div class="flex flex-col col-span-2 md:col-span-4 lg:col-span-6">
        <div class="bg-white rounded">
          <div class="px-4 py-4">
            <h2 class="text-primary text-2xl">Nichts gefunden</h2>
            <p class="text-gray-700">
              Es konnte kein passendes Resultat gefunden werden
            </p>
          </div>

          <app-static-asset
            [alt]="'Cookie Plant'"
            [extension]="'png'"
            [source]="'assets/static/empty-search'"
            class="w-full my-6"
          ></app-static-asset>
        </div>

        @if (canReset()) {
          <app-button-primary
            class="my-6 w-auto mix-blend-multiply"
            [action]="doReset"
            >Filter zurücksetzen</app-button-primary
          >
        }
      </div>
    }
  }
</div>

@if (store().total > store().skip) {
  @if ((store().started || store().loading) && store().elements.length === 0) {
    <app-spinner class="text-primary text-2xl"></app-spinner>
  } @else {
    <div class="flex flex-col items-center mt-6">
      <app-button-primary [action]="loadMore">Mehr laden</app-button-primary>
    </div>
  }
}
